﻿.component-box {
	display:			flex;
	flex-direction:		row;
	flex-wrap:			wrap;
	justify-content:	justify-content;
	align-items:		flex-start;
	align-content:		stretch;
	& > .box {
		margin:			10px;
		border:			1px solid rgba(0,0,0,0.4);
		border-radius:	5px;
		box-shadow:		0 0 15px rgba(0,0,0,0.3);
		width:			350px;
		& > .header {
			padding:		10px;
			font-size:		13px;
			font-weight:	500;
			text-align:		left;
			border-radius:	5px 5px 0 0;
			background-color:	rgba(68, 75, 90, 1);
			color:				#ffffff;
			& > .option {
				float:		right;
				font-size:	11px;
				color:		rgba(3, 169, 244, 1);
				font-weight:	300;
			}
		}
		& > .body {
			padding:		5px;
		}
	}
}

.cat-box {
	& > .box {
		& > .header {
			background-color:	rgba(55, 62, 92, 1);
			color:				#ffffff;
			font-weight:		500;
			font-size:			15px;
			padding:			10px;
		}
		
		& > .content {
			
		}
	}
}

.shell-log {
	background-color:	rgba(34, 34, 34, 1);
	margin:				0;
	padding:			3px 0;
	& > .cmd {
		text-align:		left;
		padding:		2px 5px;
		font-size:		12px;
		font-weight:	900;
		color:			#ffffff;
		word-wrap:		break-word;
		white-space:	pre-wrap;
	}
	& > .log {
		text-align:		left;
		padding:		2px 5px;
		overflow-wrap:	break-word;
		overflow-x:		auto;
		width:			100%;
		margin:			0;
		background-color:	transparent;
		font-size:		10px;
		font-weight:	400;
		color:			darken(#ffffff, 10%);
		word-wrap:		break-word;
		white-space:	pre-wrap;
	}
}