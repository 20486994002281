.merchants {
	
	.page-container {
		padding:	0 25px;
	}
	
	.page-banner {
		padding:			50px;
		border-bottom:		1px solid rgba(0,0,0,0.5);
		box-shadow:			0 2px 15px rgba(0,0,0,0.4);
		margin-bottom:		25px;
		background-color:	#ffffff;
		.title {
			font-size:		25px;
			font-weight:	900;
			color:			#333333;
			line-height:	40px;
		}
		.subtitle {
			font-size:		14px;
			font-weight:	600;
			color:			#333333;
			line-height:	25px;
		}
		.cta {
			margin-top:			20px;
			cursor:				pointer;
			display:			inline-block;
			vertical-align:		middle;
			text-decoration:	none;
			border-radius:		5px;
			padding:			0 25px;
			background-color:	rgba(66, 165, 245, 1);
			color:				#ffffff;
			font-size:			14px;
			line-height:		45px;
			font-weight:		600;
			box-shadow:			1px 1px 5px rgba(0, 0, 0, 0.2);
			transition:			transform 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out;
			position:			relative;
			
			&:hover {
				background-color:	darken(rgba(66, 165, 245, 1), 10%);
				text-decoration:	none;
			}
		}
	}
	
	
	.campaign-list {
		margin-bottom:		40px;
		& > .title {
			font-size:		25px;
			font-weight:	900;
			color:			#333333;
			line-height:	40px;
		}
	}
	
	.image-guideline {
		margin:				10px 0;
		width:				50%;
		background-color:	rgba(255, 224, 130, 0.5);
		border:				1px solid rgba(255, 183, 77, 0.8);
		padding:			10px;
		ul {
			padding:			0;
			margin:				0 10px;
			li {
				list-style-type:	square;
				font-size:			12px;
				font-weight:		500;
				padding:			0;
				margin:				0;
				strong {
					font-weight:	900;
				}
			}
		}
	}
}


.creative-selector {
	
}

.creatives {
	& > div {
		display:			inline-block;
		margin:				10px 10px 10px 0;
		& > div {
			background-size:	cover;
			background-repeat:	no-repeat;
			background-position:center center;
			position:			relative;
			width:				170px;
			height:				100px;
			border-radius:		10px;
			& > .name {
				position:	absolute;
				top:		10px;
				left:		10px;
				color:		#ffffff;
				font-size:	14px;
				font-weight:600;
				text-shadow:0 0 10px rgba(0,0,0,0.6);
			}
			& > .close {
				position:	absolute;
				top:		10px;
				right:		10px;
				color:		#ffffff;
				font-size:	14px;
				font-weight:600;
				text-shadow:0 0 10px rgba(0,0,0,0.6);
			}
			& > .overlay {
				position:			absolute;
				top:				0;
				left:				0;
				width:				100%;
				height:				100%;
				border-radius:		10px;
				background-color:	rgba(0,0,0, 0.2);
			}
			&:hover {
				& > .overlay {
					background-color:	rgba(0,0,0, 0.5);
				}
			}
		}
	}
}
	
.ui-giftcards {
	background-color:	#ffffff;
}



.ui-giftcard-selector {
	& > .name {
		background-color:	rgba(226, 231, 237, 1);
		color:				#333333;
		font-size:			13px;
		font-weight:		700;
		padding:			10px;
		a {
			font-weight:	500;
			font-size:		11px;
		}
	}
	& > .values {
		color:				#333333;
		padding:			10px;
	}
}


table.grouped-list {
	.app-dialog & {
		width:	100%;
	}
	& > thead {
		& > tr {
			& > th {
				padding:		5px;
				font-size:		13px;
				font-weight:	900;
			}
		}
	}
	& > tbody {
		& > tr.group {
			background-color:	rgba(66, 165, 245, 0);
			transition:			background-color ease-out 0.3s;
			
			& > td {
				padding:		5px;
				font-size:		11px;
				font-weight:	500;
				color:			rgba(0,0,0,0.7);
				text-align:		left;
				vertical-align:	middle;
				
				&.caret {
					width:			20px;
					font-size:		18px;
				}
				&.name {
					width:			150px;
					font-size:		14px;
					font-weight:	600;
				}
				&.country {
					width:			30px;
					font-size:		14px;
					font-weight:	600;
				}
				&.currency {
					width:			30px;
					font-size:		14px;
					font-weight:	600;
				}
				&.action {
					text-align:		right;
				}
			}
			
			&:hover {
				background-color:	rgba(66, 165, 245, 0.2);
			}
			&.selected {
				background-color:	rgba(66, 165, 245, 0.3);
			}
		}
		& > tr.giftcards {
			& > td {
				& > table {
					width:		100%;
					& > thead {
						& > tr {
							& > th {
								padding:		5px;
								font-size:		12px;
								font-weight:	900;
								text-align:		left;
							}
						}
					}
					& > tbody {
						& > tr {
							background-color:	rgba(66, 165, 245, 0);
							transition:			background-color ease-out 0.3s;
							& > td {
								cursor:			default;
								vertical-align:	middle;
								padding:		5px;
								font-size:		12px;
								font-weight:	500;
								color:			rgba(0,0,0,0.7);
								text-align:		left;
								&.toggle {
									width:		60px;
									text-align:	right;
									.active {
										color:	rgba(156, 204, 101, 1);
									}
									.inactive {
										color:	rgba(229, 115, 115, 1);
									}
								}
							}
							
							&:hover {
								background-color:	rgba(66, 165, 245, 0.1);
							}
							
							&.disabled {
								background-color:	rgba(0, 0, 0, 0.08);
								& > td {
									color:			rgba(0,0,0,0.4);
								}
								&:hover {
									background-color:	rgba(0, 0, 0, 0.15);
								}
							}
						}
					}
				}
			}
			&.selected {
				background-color:	rgba(66, 165, 245, 0.1);
			}
		}
	}
}


.campaign-table {
	background-color:	rgba(255,255,255, 1);
	padding:			10px;
	& > table {
		width:	100%;
		& > thead {
			background-color:	rgba(120, 144, 156, 1);
			color:				#ffffff;
			& > tr {
				& > th {
					padding:		8px;
					font-size:		14px;
					font-weight:	900;
					text-align:		left;
				}
			}
		}
		& > tbody {
			& > tr {
				background-color:	rgba(66, 165, 245, 0);
				transition:			background-color ease-out 0.3s;
				& > td {
					padding:		5px;
					font-size:		13px;
					font-weight:	500;
					color:			rgba(0,0,0,0.7);
					text-align:		left;
					
					&.name {
						max-width:		150px;
						font-weight:	600;
					}
					&.date {
						max-width:		110px;
					}
					&.profile {
						width:			200px;
					}
					&.number {
						width:			100px;
					}
					&.action {
						text-align:		right;
						& > span {
							&.link {
								color:			rgba(66, 165, 245, 0.6);
								font-weight:	600;
								padding:		0 5px;
								transition:		color ease-out 0.3s;
								&:hover {
									color:			rgba(66, 165, 245, 1);
								}
							}
						}
					}
					.ago {
						font-size:		10px;
						color:			rgba(0,0,0,0.6);
						line-height:	15px;
						font-weight:	500;
					}
					&.toggle {
						width:		60px;
						text-align:	right;
						.active {
							color:	rgba(156, 204, 101, 1);
						}
						.inactive {
							color:	rgba(229, 115, 115, 1);
						}
					}
				}
				&:hover {
					background-color:	rgba(66, 165, 245, 0.1);
				}
				&.selected {
					background-color:	rgba(66, 165, 245, 0.2);
					& > td {
						color:				#ffffff;
					}
				}
			}
		}
	}
}

/*
<div class="target-locator">
	<div class="search">
		<input type="text" placeholder="City, State or Country" ng-model="main.query" />
		<div class="loading" ng-if="main.loading">
			<span class="fas fa-spin fa-spinner"></span>
		</div>
		<div class="dropdown">
			<div>
				<div ng-repeat="item in main.searchResults">
					<div class="country">
						{{item.country}}
					</div>
					<div class="region">
						{{item.region}}
					</div>
					<div class="city">
						{{item.city}}
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="map">
		<div class="map-container"></div>
	</div>
</div>

*/

.target-locator {
	border:		1px solid rgba(0,0,0,0.2);
	width:		500px;
	margin:		0 auto;
	& .search {
		position:		relative;
		border-bottom:	1px solid rgba(0,0,0,0.2);
		& > input {
			width:			100% !important;
			min-width:		100%;
			max-width:		100%;
			box-sizing:		border-box;
			border:			0;
		}
		& > .loading {
			position:	absolute;
			top:		3px;
			right:		3px;
			font-size:	20px;
		}
		& > .dropdown {
			position:	relative;
			& > div {
				position:	absolute;
				width:		100%;
				top:		0;
				left:		0;
				z-index:	50000;
				background-color:	rgba(255,255,255,0.8);
				max-height:	250px;
				overflow-y:	auto;
				
				
				/*
				<div class="block">
					<div class="name">Countries</div>
					<table>
						<tbody>
							<tr ng-repeat="item in main.suggestions.countries">
								<td class="country">
									{{item.country_name}}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				*/
				
				.block {
					padding:		0;
					margin:			0;
					& > .name {
						background-color:	rgba(226, 231, 237, 1);
						color:				#333333;
						font-weight:		700;
						font-size:			13px;
						padding:			5px 10px;
					}
					
					& > .list {
						& > div {
							padding:			5px;
							background-color:	rgba(33, 150, 243, 0);
							transition:			background-color ease-out 0.3s;
							&:hover {
								background-color:	rgba(33, 150, 243, 0.3);
							}
							& > div {
								display:		inline-block;
								color:			#333333;
								font-weight:	500;
								font-size:		11px;
								padding:		5px;
								&.country {
									
								}
								&.country_code {
									
								}
								&.city {
									
								}
								&.main {
									font-weight:	900;
								}
							}
						}
					}
				}
				
				
			}
		}
	}
	& > .map {
		height:	300px;
	}
}


.ui-location-selector {
		display:				table;
		border:					1px solid rgba(0,0,0,0.2);
		background-color:		rgba(226, 231, 237, 1);
		border-radius:			5px;
		& > div {
			border-radius:		5px;
			display:			table-row;
			
			& > div {
				display:			table-cell;
				padding:			10px;
				color:				#333333;
				background-color:	rgba(33, 150, 243, 0);
				transition:			background-color ease-out 0.3s;
				cursor:				default;
				&.remove {
					cursor:				pointer;
					transition:			background-color ease-out 0.3s;
					background-color:	rgba(244, 67, 54, 0);
					&:hover {
						background-color:	rgba(244, 67, 54, 0.5);
					}
				}
				
				.main {
					font-size:		12px;
					font-weight:	600;
				}
				.secondary {
					font-size:		11px;
					font-weight:	400;
					padding:		0 5px;
				}
				.tertiary {
					font-size:		10px;
					font-weight:	300;
				}
				&.active {
					background-color:	rgba(33, 150, 243, 1);
					color:				#ffffff;
				}
				&:first-child {
					border-radius:		5px 0 0 5px;
				}
				&:last-child {
					border-radius:		0 5px 5px 0;
				}
			}
			
			&:hover {
				& > div {
					background-color:	rgba(33, 150, 243, 0.3);
				}
			}
		}
}