.publishers {
	
	.page-container {
		padding:	0 25px;
	}
	
	.page-banner {
		padding:			50px;
		border-bottom:		1px solid rgba(0,0,0,0.5);
		box-shadow:			0 2px 15px rgba(0,0,0,0.4);
		margin-bottom:		25px;
		background-color:	#ffffff;
		.title {
			font-size:		25px;
			font-weight:	900;
			color:			#333333;
			line-height:	40px;
		}
		.subtitle {
			font-size:		14px;
			font-weight:	600;
			color:			#333333;
			line-height:	25px;
		}
		.cta {
			margin-top:			20px;
			cursor:				pointer;
			display:			inline-block;
			vertical-align:		middle;
			text-decoration:	none;
			border-radius:		5px;
			padding:			0 25px;
			background-color:	rgba(66, 165, 245, 1);
			color:				#ffffff;
			font-size:			14px;
			line-height:		45px;
			font-weight:		600;
			box-shadow:			1px 1px 5px rgba(0, 0, 0, 0.2);
			transition:			transform 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out;
			position:			relative;
			
			&:hover {
				background-color:	darken(rgba(66, 165, 245, 1), 10%);
				text-decoration:	none;
			}
		}
	}
	
}
