
.ui-test-1 {
	margin:				20px auto;
	width:				200px;
	border-radius:		5px;
	background-color:	rgba(255,255,255, 1);
	box-shadow:			0 2px 5px rgba(0,0,0,0.2);
	& > div {
		padding:		10px 20px;
		font-size:		13px;
		font-weight:	500;
		color:			rgba(0, 143, 251, 1);
		border-left:	4px solid rgba(0, 143, 251, 1);
		&:first-child {
			border-radius:		5px 5px 0 0;
		}
		&:last-child {
			border-radius:		0 0 5px 5px;
		}
		transition:			background-color 0.3s ease-out, color 0.3s ease-out, transform 0.6s ease-out;
		&:hover {
			color:				rgba(255, 255, 255, 1);
			background-color:	rgba(0, 143, 251, 1);
			border-left:		4px solid rgba(233, 30, 99, 1);
			transform:			translate(10px);
		}
	}
}

.ui-test-2 {
	display:		flex;
	flex-wrap:		wrap;
    align-items:	stretch;
    & > div {
		flex:				calc(25% - 5px);
		box-sizing:			border-box;
		padding:			20px;
		text-align:			center;
		border-radius:		5px;
		background-color:	rgba(255,255,255, 1);
		box-shadow:			0 2px 5px rgba(0,0,0,0.2);
    	margin:				10px;
	}
}

.ui-test-3 {
	margin:				20px auto;
	width:				200px;
	border-radius:		5px;
	background-color:	rgba(255,255,255, 1);
	box-shadow:			0 2px 5px rgba(0,0,0,0.2);
	padding:			10px;
	& > div {
		display:		none;
		position:		relative;
		& > div {
			position:	absolute;
			width:		100%;
			top:		10px;
			right:		0;
			border-radius:		0 0 3px 3px;
			background-color:	rgba(255,255,255, 1);
			box-shadow:			0 2px 5px rgba(0,0,0,0.2);
			& > div {
				transition:		background-color 0.3s ease-out, color 0.3s ease-out;
				
				padding:		10px 20px;
				font-size:		12px;
				font-weight:	500;
				color:			rgba(0, 143, 251, 1);
				border-left:	4px solid rgba(0, 143, 251, 1);
				&:last-child {
					border-radius:		0 0 3px 3px;
				}
				&:hover {
					color:				rgba(255, 255, 255, 1);
					background-color:	rgba(0, 143, 251, 1);
				}
			}
		}
	}
	&:hover {
		& > div {
			display:		block;
		}
	}
}



	
.alert {
	position:			relative;
	padding:			10px;
	font-size:			11px;
	color:				#333333;
	background-color:	#64B5F6;
	text-align:			left;
	
	.close {
		position:	absolute;
		top:		10px;
		right:		10px;
		font-size:	18px;
	}
	&.info {
		background-color:	#29B6F6;
		color:				#ffffff;
	}
	&.warning {
		background-color:	#FFB74D;
		color:				#333333;
	}
	&.error {
		background-color:	rgba(198, 40, 40, 1);
		color:				#ffffff;
	}
}


.angular-json-explorer {
	padding:	10px;
	font-size:	10px;
	
	li {
		font-size: 12px;
	}
	.prop {
		font-weight:	600;
	}
	.string {
		color:			rgba(21, 101, 192, 1) !important;
		font-weight:	400;
	}
	.num {
		color:			rgba(104, 159, 56, 1) !important;
		font-weight:	400;
	}
}


@keyframes delayedHiding {
	0% {
		border:	2px solid #ff0000;
	}
	99% {
		
	}
	100% {
		display:	none;
	}
}

.hover-menu {
	line-height:			60px;
	transition:				0.3s ease-out;
	border-top: 			4px solid;
   	border-top-color: 		transparent;
	& > div:first-child {
	    /*display: 		flex;*/
	    height: 		100%;
	    width:			100%;
	    align-items:	center;
	    .tag {
	    	font-size: 		10px;
    		margin-left: 	7px;
    		padding:		2px 6px;
	    }	
	}
	& > .hover-menu-list {
		display:	none;
		width:		100%;
		position:	relative;
		& > .list {
			position:						absolute;
			top:							-1px;
			right:							-9px;
			width:							170px;
			z-index:						50000;
			background-color:				#ffffff;
			box-shadow:						1px 2px 3px 0 rgba(0, 0, 0, 0.66);
			max-height:						300px;
			overflow-y:						auto; /*auto, not scroll. Otherwise the scrollbar is always visible*/ 
			overflow-x:						hidden;
			border-top:						0;
			border-bottom-right-radius:		3px;
			border-bottom-left-radius:		3px;
			
			
			& > div, & > a {
				width:				150px;
				white-space:		nowrap;
				overflow:			hidden;
				text-overflow:		ellipsis;
				display:			block;
				text-decoration:	none;
				padding:			10px;
				font-size:			12px;
				font-weight:		400;
				text-align:			left;
				transition:			background-color ease-out 0.3s;
				line-height:		20px;
				color: 				#fff;
    			background-color: 	#373e5c;
    			/*border-bottom:		1px solid #e7e9eb;*/
    			&:last-child {
    				border-bottom: 	0;
    			}
				&:hover {
					background-color: 		#525c86;
				}
				&.selected {
					background-color: 		lighten(#525c86, 5%);
				}
			}
			&.light {
				& > div, & > a {
					background-color: 	transparent;
					color:				#333333;
					&:hover {
						background-color: 		rgba(3, 155, 229, 0.4);
					}
					&.selected {
						background-color: 		rgba(3, 155, 229, 0.5);
					}
					&.sep {
						padding:		2px;
						border-bottom:	1px dotted rgba(0, 0, 0, 0.3);
						margin:			0 auto;
						margin-bottom:	5px;
						&:hover {
							background-color: 	transparent;
						}
					}
				}
			}
		}
	}
	/*
		hover-menu should control hover-menus and nothing else.
		Create a separate class for this kind of custom style that only apply in the header bar.
		Otherwise, all hover-menus accross the site get that style. I use hover menus in various management UIs.
	*/
	/*&:hover {
		background: 			rgba(255,255,255,0.1);
		cursor:					pointer;
		border-top-color:		#42a5f5;
		.hover-menu-list {
			display:	block;
		}
	}*/
	&:hover {
		.hover-menu-list {
			display:	block;
		}
		.hover-menu-list.hide {
			display:	none;
		}
	}
}


.ng-highlight {
	/*background-color:	rgba(255, 204, 128, 0.3);*/
	color:	rgba(216, 27, 96, 1);
}




.merchant-account-found-box {
	width:			80%;
	margin:			10px auto;
	& > .title {
		color:				#333333;
		font-size:			14px;
		font-weight:		500;
		margin-bottom:		10px;
		text-align:			center;
	}
	& > .list {
		border-radius:	5px;
		border-radius:	5px;
		box-shadow:		0 2px 6px rgba(0, 0, 0, 0.2);
		display:		table;
		& > div {
			display:			table-row;
			background-color:	rgba(3, 169, 244, 0);
			transition:			background-color ease-out 0.3s;
			&:hover {
				background-color:	rgba(2, 136, 209, 0.3);
			}
			
			& > div {
				display:		table-cell;
				border-bottom:	1px dashed rgba(0,0,0,0.1);
				vertical-align:	middle;
				padding:		10px;
				font-size:		12px;
				font-weight:	400;
				
				.name {
					font-size:		14px;
					font-weight:	500;
					margin-bottom:	10px;
				}
				
				.gc {
					border-radius:	3px;
					padding:		3px 5px;
					font-size:		9px;
					font-weight:	400;
					color:			#333333;
					background-color:	rgba(33, 150, 243, 0.1);
					margin:			1px;
					display:		inline-block;
				}
				
				&:last-child {
					width:			140px;
					text-align:		right;
				}
			}
			
			&:first-child {
				border-radius:	5px 5px 0 0;
			}
			&:last-child {
				border-radius:	 0 0 5px 5px;
				& > div {
					border-bottom:	0;
				}
			}
		}
	}
}



.server-status {
	background-color:	rgba(255,255,255, 1);
	padding:			20px;
	& > table {
		margin:		0 auto;
		& > thead {
			& > tr {
				& > th {
					padding:	5px;
				}
			}
		}
		& > tbody {
			& > tr {
				& > td {
					padding:	5px;
					text-align:	center;
					background-color:	rgba(0,0,0, 0.1);
					vertical-align:	middle;
					&.up {
						background-color:	rgba(174, 213, 129, 1);
						color:				rgba(255,255,255,1);
					}
					&.down {
						background-color:	rgba(239, 154, 154, 1);
						color:				rgba(255,255,255,1);
					}
					&.name {
						background-color:	transparent;
						color:				#333333;
						text-align:			right;
						font-weight:		700;
						font-size:			13px;
					}
					.last-contact {
						font-size:		10px;
					}
				}
			}
		}
	}
}



.text-ok {
	color:	#4CAF50;
}
.text-no {
	color:	#D84315;
}
.text-red {
	color:	#D32F2F;
}
.text-green {
	color:	#8BC34A;
}
.text-blue {
	color:	#039BE5;
}

body {
	overflow:	hidden;
}

[ng-click] {
	cursor:	pointer;
}

a {
	text-decoration:	none;
	color:				#42A5F5;
};

code {
	background-color:	rgba(120, 144, 156, 0.9);
	border-radius:		2px;
	padding:			2px 3px;
	color:				#ffffff;
	font-size:			11px;
	font-weight:		300;
}

input[readonly] {
	background-color:	rgba(226, 231, 237, 0.9) !important;
	border:				0;
	border-radius:		3px;
}

.overflow-y {
	overflow-y: auto;
	position:	relative;
}

.ace_editor {
	height:	300px;
	
	&.xs {
		height:	50px;
	}
	&.small {
		height:	150px;
	}
	&.medium {
		height:	500px;
	}
	&.large {
		height:	700px;
	}
}


.fixed-floating-bottom {
	position:			absolute;
	bottom:				0;
	width:				100%;
	height:				2px;
	background-color:	#ffcc00;
	z-index:			5000;
	text-align:			center;
	& > div {
		position:	relative;
		display:	inline-block;
		transform:	translateY(-100%);
	}
}

.ui-tools {
	position:				absolute;
	top:					0;
	left:					0;
	width:					100%;
	height:					100%;
	overflow-y:				hidden;
	font-family: 			'Roboto', sans-serif;
	background: 			#F7FCFF;
	background-image:		url('/swish.svg');
	background-position:	top center;
	background-size:		100% auto;
	background-repeat:		no-repeat;
	/*background: linear-gradient(180deg, rgba(225,228,234,1) 0%, rgba(227,234,240,1) 100%);*/
	
	& > .ui-header {
		background-color:	rgba(55, 62, 92, 1);
		color:				#ffffff;
		font-weight:		700;
		font-size:			15px;
		text-align:			center;
		line-height:		45px;
		border-bottom:		1px solid rgba(0,0,0,0.4);
		& > div.navbar-container {
			box-sizing:			border-box;
			width:				100%;
			display:			flex;
			justify-content:	space-between;
			padding:			0 16px;
			height:				60px;
			align-items:		center;
			& > div.logo {
				display:		flex;
				height:			100%;
				padding:		0 16px;
				line-height:	unset;
				a {
					display:			flex;
					align-items:		center;
					background-color:	transparent;
					img {
						max-width:		100%;
					    margin: 		0;
					    padding: 		0;
					    border-style: 	none;
					}
				}
			}
			& > div.nav-links {
				height:				100%;
				display:			flex;
				justify-content:	space-between;
				padding:			0 16px;
				align-items:		center;
				& > div.menu {
					text-align:		right;
					padding: 		0 8px;
					.menu-desktop {
						& > div, & > a {
							display:			inline-block;
							vertical-align:		top;
							padding:			2px 10px;
							font-size:			15px;
							font-weight:		600;
							color:				#ffffff;
							text-decoration:	none;
							transition:			color ease-out 0.3s;
							&:hover {
								color:				rgba(66, 165, 245, 1);
								cursor:				pointer;
								text-decoration:	none;
							}
						}
						@media screen and (max-width: 992px) {
							display:	none;
						}
					}
					.menu-mobile {
						display:	none;
						@media screen and (max-width: 992px) {
							display:	block;
						}
					}
				}
				
				& > div.sep {
					width:			5px;
					text-align:		center;
					margin:			0 7px;
					& > div {
						height:				20px;
						width:				1px;
						background-color:	rgba(0,0,0, 0.6);
						display:			inline-block;
						vertical-align:		middle;
					}
				}
								
								
				& > div.account {
					text-align:		center;
					padding:		0 8px;
					& > .avatar {
						width:				30px;
						height:				30px;
						background-color:	rgba(0,0,0, 0.5);
						border-radius:		50%;
						display:			inline-block;
						vertical-align:		middle;
					}
					svg {
						margin-right:		7px;
					}
				}
								
								
				& > div.notifications {
					width:		40px;
					text-align:	center;
					font-size:	28px;
				}
				
			}
		}
	}
					
					
	& > .ui-content {
		overflow-y:	auto;
		color:		#333333;
		position:	relative;
	}
}


.ng-highlight {
	/*background-color:	rgba(255, 204, 128, 0.3);*/
	color:	rgba(216, 27, 96, 1);
}




.account-dashboard {
	margin:				20px auto;
	max-width:			300px;
	& > .title {
		font-size:		14px;
		font-weight:	700;
		color:			#333333;
		text-align:		center;
		margin:			10px;
	}
	& > .subtitle {
		font-size:		11px;
		font-weight:	500;
		color:			rgba(0,0,0,0.6);
		text-align:		center;
		margin:			0 10px 20px;
	}
	& > .list {
		display:			table;
		width:				100%;
		margin:				0 auto;
		border-radius:		5px;
		background-color:	rgba(255,255,255, 0.8);
		box-shadow:			0 2px 6px rgba(0,0,0,0.1);
		& > div, & > a {
			display:	table-row;
			background-color:	rgba(66, 165, 245, 0);
			transition:		background-color ease-out 0.3s;
			&:hover {
				background-color:	rgba(66, 165, 245, 1);
			}
			& > div {
				display:		table-cell;
				padding:		10px;
				vertical-align:	middle;
				text-align:		left;
				
				.name {
					font-size:		13px;
					font-weight:	900;
					color:			#333333;
				}
				.about {
					font-size:		11px;
					font-weight:	400;
					color:			rgba(0,0,0,0.5);
				}
				&:last-child {
					border-radius:	5px;
				}
			}
		}
	}
}




/*
.accounts-list {
	display:		flex;
	flex-wrap:		wrap;
    align-items:	stretch;
    
    & > div {
		flex:		300px;
		margin:		10px;
		padding:	5px;
		border:		1px solid #ccc;
		box-shadow:	2px 2px 6px 0px  rgba(0,0,0,0.3);
		background-color:	rgba(255,255,255, 1);
		border-radius:	5px;
		& > div {
			&.name {
				font-size:		14px;
				font-weight:	900;
				color:			#333333;
			}
			&.users {
				& > div {
					&.title {
						font-size:		12px;
						font-weight:	500;
						color:			#333333;
					}
					&.list {
						display:		flex;
						flex-wrap:		wrap;
					    align-items:	stretch;
					    
						& > .user {
							flex:				30%;
							margin:				5px;
							border:				1px solid rgba(0,0,0,0.1);
							border-radius:		5px;
							display:			table;
							background-color:	rgba(66, 165, 245, 0);
							transition:			background-color ease-out 0.3s;
							&:hover {
								background-color:	rgba(66, 165, 245, 1);
							}
							& > div {
								display:		table-cell;
								padding:		5px;
								vertical-align:	middle;
								text-align:		left;
								
								.name {
									font-size:		13px;
									font-weight:	900;
									color:			#333333;
								}
								.about {
									font-size:		11px;
									font-weight:	400;
									color:			rgba(0,0,0,0.5);
								}
								&:first-child {
									border-radius:	5px 0 0 5px;
									width:			35px;
									img {
										width:			30px;
										height:			30px;
										border-radius:	50%;
									}
								}
								&:last-child {
									border-radius:	0 5px 5px 0;
								}
							}
						}
					}
				}
			}
		}
	}
}
*/


.account-list {
	background-color:	#ffffff;
	
	& > .title {
		font-size:		16px;
		font-weight:	600;
		letter-spacing:	1px;
		color:			#333333;
		padding:		5px;
	}
	
	table.cols {
		width:	100%;
		& > tbody {
			& > tr {
				& > td {
					padding:		0;
					vertical-align:	top;
				}
			}
		}
	}
	
	table.acc-list {
		width:	100%;
		& > thead {
			& > tr {
				& > th {
					padding:		5px;
					font-size:		13px;
					font-weight:	900;
				}
			}
		}
		& > tbody {
			& > tr {
				& > td {
					vertical-align:	top;
					cursor:			default;
					padding:		5px;
					font-size:		13px;
					font-weight:	500;
					color:			rgba(0,0,0,0.7);
					text-align:		left;
					
					&.name {
						max-width:		150px;
						font-weight:	600;
					}
					&.status {
						width:			50px;
						text-align:		center;
					}
					&.shortcode {
						max-width:		40px;;
					}
					&.date {
						max-width:		110px;
					}
					&.profile {
						width:			200px;
					}
					&.number {
						width:			100px;
						text-align:		right;
					}
					&.right {
						text-align:		right;
					}
					&.action {
						text-align:		right;
						& > span {
							&.link {
								color:			rgba(66, 165, 245, 0.6);
								font-weight:	600;
								padding:		0 5px;
								transition:		color ease-out 0.3s;
								&:hover {
									color:			rgba(66, 165, 245, 1);
								}
							}
						}
						& > .btn {
							cursor:				pointer;
							display:			inline-block;
							vertical-align:		middle;
							text-decoration:	none;
							border-radius:		5px;
							background-color:	rgba(66, 165, 245, 1);
							color:				#ffffff;
							font-weight:		600;
							box-shadow:			1px 1px 5px rgba(0, 0, 0, 0.2);
							transition:			transform 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out;
							font-size:			12px;
							line-height:		35px;
							padding:			0 15px;
							margin:				5px;
							&:hover {
								background-color:	darken(rgba(66, 165, 245, 1), 10%);
								text-decoration:	none;
							}
						}
					}
					.ago {
						font-size:		10px;
						color:			rgba(0,0,0,0.6);
						line-height:	15px;
						font-weight:	500;
					}
				}
				background-color:	rgba(33, 150, 243, 0);
				transition:			background-color ease-out 0.3s;
				&:hover {
					background-color:	rgba(33, 150, 243, 0.3);
				}
				
				&.selected {
					background-color:	rgba(66, 165, 245, 0.7);
					& > td {
						color:				#ffffff;
					}
				}
				&.odd {
					background-color:	rgba(144, 164, 174, 0.2);
					&:hover {
						background-color:	rgba(33, 150, 243, 0.3);
					}
				}
			}
		}
	}
	&.autosize {
		& > table {
			width:	auto;
			margin:	0 auto;
		}
	}
	&.center {
		& > table {
			th {
				text-align:	center;
			}
			td {
				text-align:	center;
			}
		}
	}
	&.border {
		& > table {
			td {
				border-right:	1px solid rgba(0,0,0,0.1);
				border-bottom:	1px solid rgba(0,0,0,0.1);
				&:last-child {
					border-right:	0;
				}
			}
		}
	}
}



.list-widget {
	overflow-y:		auto;
	max-height:		350px;
	& > table {
		width:		100%;
		& > thead {
			& > tr {
				& > th {
					font-size:		10px;
					font-weight:	900;
					color:			#333333;
					padding:		2px;
					text-align:		left;
				}
			}
		}
		& > tbody {
			& > tr {
				& > td {
					font-size:		10px;
					font-weight:	500;
					color:			rgba(0,0,0,0.6);
					padding:		2px;
					&.right {
						text-align:	right;
					}
				}
				cursor:				default;
				background-color:	rgba(66, 165, 245, 0);
				transition:		background-color ease-out 0.3s, color ease-out 0.3s;
				&:hover {
					background-color:	rgba(66, 165, 245, 0.1);
				}
			}
		}
	}
}