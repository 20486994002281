﻿.hover-menu {
	.hmenu {
		position:	relative;
		display:	none;
		& > div {
			position:	absolute;
			z-index:	50000;
			top:		0;
			left:		0;
			box-shadow:	2px 2px 6px 0px  rgba(0,0,0,0.3);
			background-color:	rgba(255,255,255, 1);
			border-radius:	0 0 2px 2px;
			max-height:		250px;
			overflow-y:		auto;
			min-width:		100px;
			& > div {
				padding:		5px;
				font-size:		12px;
				color:			#333333;
				background-color:	rgba(3, 169, 244, 0);
				transition:		background-color 0.3s ease-out;
				&:hover {
					background-color:	rgba(3, 169, 244, 0.2);
				}
			}
		}
	}
	&:hover {
		.hmenu {
			display:	block;
		}
	}
}


.dashboard-ui {
	position:	relative;
	
	.dashboard-toolbar {
		background-color:	rgba(255,255,255, 0.8);
		& > div {
			display:	inline-block;
			margin:		0;
			padding:	10px;
			font-size:	12px;
		}
	}
	
	.floating-menu {
		position:			absolute;
		bottom:				20px;
		right:				20px;
		& > div {
			margin:			10px;
			width:			50px;
			height:			50px;
			line-height:	50px;
			font-size:		24px;
			color:			#ffffff;
			background-color:	rgba(3, 169, 244, 0.5);
			transition:		background-color 0.3s ease-out;
			&:hover {
				background-color:	rgba(3, 169, 244, 0.8);
			}
			text-align:		center;
			border-radius:	50%;
			box-shadow:		2px 2px 15px rgba(0,0,0,0.5);
			&.selected {
				background-color:	rgba(3, 169, 244, 1);
				color:				#ffffff;
			}
		}
	}
	.widget-list {
		position:			fixed;
		bottom:				20px;
		left:				20px;
		background-color:	rgba(255,255,255, 1);
		border-radius:		5px;
		box-shadow:			2px 2px 10px rgba(0,0,0,0.3);
		& > div {
			padding:		5px;
			font-size:		12px;
			text-align:		left;
			color:			#333333;
			border-radius:	5px;
			background-color:	rgba(3, 169, 244, 00);
			transition:		background-color 0.3s ease-out;
			&.active {
				background-color:	rgba(3, 169, 244, 0.1);
			}
			&:hover {
				background-color:	rgba(3, 169, 244, 0.5);
			}
			&.selected {
				background-color:	rgba(3, 169, 244, 1);
				color:				#ffffff;
			}
		}
	}
	.menu {
		position:			fixed;
		bottom:				20px;
		right:				20px;
		background-color:	rgba(255,255,255, 1);
		border-radius:		5px;
		box-shadow:			2px 2px 10px rgba(0,0,0,0.3);
		& > div.action {
			padding:		5px;
			font-size:		12px;
			text-align:		left;
			color:			#333333;
			border-radius:	5px;
			background-color:	rgba(3, 169, 244, 00);
			transition:		background-color 0.3s ease-out;
			&:hover {
				background-color:	rgba(3, 169, 244, 0.5);
			}
			&.selected {
				background-color:	rgba(3, 169, 244, 1);
				color:				#ffffff;
			}
		}
		& > div.sep {
			margin:			2px 30%;
			border-bottom:	1px dotted rgba(0,0,0,0.1);
		}

	}
	
	.variables {
		padding:	0;
	}
}


.widgets {
	display:		flex;
	flex-wrap:		wrap;
	align-items:	stretch;

	& > .widget {
		position:	relative;
		flex:		40%;
		margin:		10px;
		border:		1px solid #ccc;
		box-shadow:	2px 2px 6px 0px  rgba(0,0,0,0.3);
		background-color:	rgba(255,255,255, 1);
		border-radius:	5px;
		&.w50 {
			flex:	50%;
		}
		&.w75 {
			flex:	75%;
		}
		&.w100 {
			flex:	100%;
		}
		& > div {
			overflow:		hidden;
			padding:		5px;
			& > .title {
				font-size:		14px;
				font-weight:	900;
				color:			#333333;
				padding:		10px;
			}
			& > .options {
				position:		absolute;
				top:			5px;
				right:			5px;
				& > div {
					display:		inline-block;
					margin:			1px;
					padding:		5px 10px;
					font-size:		12px;
					border:			1px solid rgba(0,0,0,0.05);
					background-color:	rgba(3, 169, 244, 0);
					transition:		background-color 0.3s ease-out;
					&:hover {
						background-color:	rgba(3, 169, 244, 0.2);
					}
					border-radius:	5px;
				}
			}
		}
	}
}

.variables {
	& > .list, &.list {
		display:	table;
		& > div {
			display:	table-row;
			background-color:	rgba(3, 169, 244, 0);
			transition:		background-color 0.3s ease-out;
			&:hover {
				background-color:	rgba(3, 169, 244, 0.2);
			}
			& > div {
				display:	table-cell;
				padding:	2px;
				input {
					padding:		2px;
					font-size:		11px;
					border:			1px solid rgba(0,0,0,0.1);
					border-radius:	5px;
				}
				&.type {
					font-size:		10px;
				}
			}
		}
	}
	& > .add {
		display:	inline-block;
		color:		rgba(3, 169, 244, 1);
		font-size:	11px;
		padding:	5px;
		font-weight:	400;
	}
}
	
.datasources {
	display:		table;
	width:			100%;
	border-radius:	0 0 5px 5px;
	& > div {
		display:	table-row;
		& > div {
			display:		table-cell;
			vertical-align:	top;
			&:first-child {
				width:			150px;
				border-right:	1px dotted rgba(0,0,0,0.1);
				.list {
					display:	table;
					width:		100%;
					border-radius:	5px 0 0 5px;
					& > div {
						display:			table-row;
						background-color:	rgba(3, 169, 244, 0);
						transition:			background-color 0.3s ease-out;
						&.selected {
							background-color:	rgba(3, 169, 244, 0.1);
						}
						&:hover {
							background-color:	rgba(3, 169, 244, 0.2);
						}
						& > div {
							display:	table-cell;
							padding:	10px;
							font-size:	13px;
							font-weight:500;
							color:		#333333;
						}
					}
				}
				.add {
					text-align:	center;
					color:		rgba(3, 169, 244, 1);
					font-size:	11px;
					padding:	5px;
					font-weight:	400;
				}
			}
			&:last-child {
				padding-left:	10px;
			}
		}
	}
}